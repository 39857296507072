.slider-container {
    width: 80%;
    margin: 0 auto;
  }
  
  /* Style for individual slides */
  .slick-slide {
    transition: transform 0.5s ease-in-out;
    height: 530px;
  }
  
  /* Style for dots (pagination) */
  .slick-dots {
    bottom: 10px;
    list-style: none;
    text-align: center;
  }
  
  .slick-dots li {
    display: inline-block;
    margin: 0 5px;
  }
  
  .slick-dots li button {
    font-size: 12px;
    border: none;
    background: #ccc;
    border-radius: 50%;
    width: 12px;
    height: 12px;
    cursor: pointer;
  }

  .slick-dots li button::before {
    color: transparent;
  }
  
  .slick-dots li.slick-active button {
    background: #007bff; /* Active dot color */
  }

  .slick-dots li.slick-active button::before {
    color: transparent;
  }